
import { defineComponent } from 'vue';

type Colors =
| 'primary'
| 'secondary'
| 'error';

interface ComponentProps {
  is: string;
  type: string;
  to?: string;
  href?: string;
  target?: string;
  rel?: string;
  onclick: () => void;
}

export default defineComponent({
  name: 'ButtonBase',
  props: {
    click: {
      type: Function,
    },
    className: {
      type: String,
      required: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    type: {
      type: String,
      required: false,
      default: 'button'
    },
    to: {
      type: String,
      required: false,
    },
    href: {
      type: String,
      required: false,
    },
    component: {
      type: String,
      required: false,
      default: 'button',
    },
    color: {
      type: String,
      required: false,
      validator(value: Colors): boolean {
        return new Set(['primary', 'secondary', 'error'])
          .has(value);
      }
    }
  },
  data() {
    const props: ComponentProps = {
      is: this.component,
      type: this.type,
      onclick: this.click,
    };
    if (this.to) {
      props.is = 'router-link';
      props.to = this.to;
    } else if (this.href) {
      props.is = 'a';
      props.href = this.href;
      props.target = '_blank';
      props.rel = 'noopener noreferrer';
    };
    return {
      props,
      renderedComponent: props.is,
    };
  },
});
